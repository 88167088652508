.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header h1 {
  font-weight: 300;
}

.sitetext h2 {
  font-weight: 300;
}

.header {
  text-align: center;
  padding: 150px 25px 50px 25px;
}

.sitelink {
  text-align: center;
  padding: 25px;
}

.siteimg img {
  border-radius: 50%;
}

.sitetext {
  padding-top: 15px;
}

.sitelink:hover, 
.siteline:active {
  cursor: pointer;
}

.sitelink:hover {
  background-color: #f7f7f7;
}

.sitelink:active {
  background-color: #f1f1f1;
}

@media (max-width: 700px) {
  .header {
    padding-top: 75px;
  }
}

.commingSoon {
  position: fixed;
  top: 40%;
  width: 100%;
  right: 0;
  left: 0;
  background-color: #1b50b3;
  color: white;
  text-align: center;
}